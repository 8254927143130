import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as QliroCheckoutName } from '@spa-ec/components/QliroCheckout/QliroCheckout.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as FooterName } from '@spa-ec/components/Footer/Footer.theme';
import { NAME as ReplacementTableName } from '@spa-ec/components/ReplacementHelper/ReplacementTable.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showSideNavigationOnStaticPages: true,
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
    linkColor: theme.col.secondary.fg,
    linkColorHover: theme.col.secondary.peer,
    sideContentPadding: 'px-4',
};
const qliroCheckoutTheme = {
    primaryColor: theme.col.primary.fg,
    callToActionColor: theme.col.primary.fg,
    callToActionHoverColor: theme.col.primary.peer,
};
const pageTitleTheme = {
    noBorder: true,
    showLogoutIcon: false,
    combineSections: true,
};
const cartTableTheme = {
    showProductDetails: true,
    showPerKronaInCartTable: false,
};
const footerTheme = {
    showNewsLetter: false,
};
const replacementTableTheme = {
    hideReplaceRegretButton: true,
};
export default {
    [ContentPageName]: contentPageTheme,
    [QliroCheckoutName]: qliroCheckoutTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [FooterName]: footerTheme,
    [ReplacementTableName]: replacementTableTheme,
};
