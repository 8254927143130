const themeGen = theme => { // eslint-disable-line
  const res = {
    siteLogo: {
      imagesRootPath: '/images/sitelogo',
      paths: {
        b2c: {
          se: {
            sv: '/inky_logo_se_v2.svg',
          },
          dk: {
            da: '/inky_logo_white_dk_v3.svg',
          },
          fi: {
            fi: '/inky_logo_white_fi_v3.svg',
          },
          no: {
            no: '/inky_logo_white_no_v3.svg',
          },
          default: '/inky_logo_se_v2.svg',
        },
        b2b: {
          default: '/inky_logo_se_v2.svg',
        },
      },
    },
  };
  return res;
};

export default themeGen;
